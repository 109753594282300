import '../../styles/_landings.scss';
import Footer from '../../components/Footer';
import SecondSlide from './SecondSlide';

export const SportyClub = () => {
  return (
    <>
      <div className="landing">
        <div className="sporty-landing-home">
          <SecondSlide />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SportyClub;
