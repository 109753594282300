import './_header.scss';
import Logo from '../../assets/icons/logo.png';

export const Header = () => {
  return (
    <>
      <div className="header">
        <img className="header-logo" src={Logo} alt="logo" />
      </div>
    </>
  );
};

export default Header;
