import React from 'react';
import './_secondSlide.scss';
import '../../../styles/_landings.scss';

import Apple from '../../../assets/images/apple.svg';
import Android from '../../../assets/images/android.svg';
import image1 from '../../../assets/images/passsport/passsport-group1.png';
import image2 from '../../../assets/images/passsport/passsport-group2.png';
import image3 from '../../../assets/images/passsport/passsport-group3.png';
import image4 from '../../../assets/images/passsport/passsport-group4.png';

const SecondSlide = () => {
  return (
    <div className="second-slide">
      <div className="second-slide-images">
        <div className="second-slide-images-first">
          <img src={image1} alt="passsport" />
          <img src={image2} alt="passsport" />
        </div>
        <div className="second-slide-images-second">
          <img src={image3} alt="passsport" />
          <img src={image4} alt="passsport" />
        </div>
        <div className="second-slide-images-overlay" />
      </div>
      <div className="second-slide-subtitles">
        <div>
          <h2>
            Keep <span className="green"> doing </span>
          </h2>
          <h2>
            the <span className="green">activities</span> you love
          </h2>
          <h2>
            <span className="green">wherever </span>
            you are
          </h2>
        </div>
        <div className="second-slide-store-button">
          <a href={window.location.hostname} title="Check Sporforya IOS app">
            <img src={Apple} alt="Apple" className="app-image" />
          </a>
          <a
            href={window.location.hostname}
            title="Check Sporforya Android app">
            <img src={Android} alt="Apple" className="app-image" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecondSlide;
