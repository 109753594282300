import React from 'react';
import './_secondSlide.scss';
import '../../../styles/_landings.scss';

import Apple from '../../../assets/images/apple.svg';
import Android from '../../../assets/images/android.svg';
import image1 from '../../../assets/images/playlocal/playlocal-group5.jpg';
import image2 from '../../../assets/images/playlocal/playlocal-group6.jpg';
import image3 from '../../../assets/images/playlocal/playlocal-group7.jpg';
import image4 from '../../../assets/images/playlocal/playlocal-group8.jpg';

const ThirdSlide = () => {
  return (
    <div className="playlocal-third-slide">
      <div className="playlocal-third-slide-images">
        <div className="playlocal-third-slide-images-first">
          <img src={image1} alt="passsport" />
          <img src={image2} alt="passsport" />
        </div>
        <div className="playlocal-third-slide-images-second">
          <img src={image3} alt="passsport" />
          <img src={image4} alt="passsport" />
        </div>
        <div className="playlocal-third-slide-images-overlay" />
      </div>
      <div className="playlocal-third-slide-subtitles">
        <div className="playlocal-third-slide-subtitles-container">
          <h2>
            List your sports activities for <span className="green">free</span>
          </h2>
          <h2>
            Manage services and get <span className="green">paid</span> fast
          </h2>
          <h2>
            Get new <span className="green">customers</span> make more money
          </h2>
        </div>
      </div>

      <div className="third-slide-store-button">
        <a
          href={window.location.hostname}
          title="Check Sporforya IOS app">
          <img src={Apple} alt="Apple" className="app-image" />
        </a>
        <a
          href={window.location.hostname}
          title="Check Sporforya Android app">
          <img src={Android} alt="Apple" className="app-image" />
        </a>
      </div>
    </div>
  );
};

export default ThirdSlide;
