import React, { SyntheticEvent, useState } from 'react';
import './_contactForm.scss';
import axios from 'axios';
import { baseUrl } from '../../../constant/baseUrl';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-line.svg';

import LogoLabel from '../../../assets/icons/sporti-logo-horizontal.png';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
interface ContactFormProps {
  type: string;
  setShowToast: (state: boolean) => void;
  onClose: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({
  type,
  onClose,
  setShowToast,
}) => {
  const [value, setValue] = useState({
    email: '',
    name: '',
    city: '',
  });

  const subTitle = {
    user: "Be first to get the App on 12/01/24, and $10 for inviting a friend",
    provider:
      'Get pre-launch access to list and sell your Sports Activities for free!',
  };

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const onRequestApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { email, name, city } = value;
    if (emailRegex.test(value?.email)) {
      setLoading(true);
      axios
        .post(`${baseUrl}user/user-subscriptions`, { email, name, city, type })
        .then(res => {
          setShowToast(true);
          setLoading(false);
          onClose();
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      setError('Please enter a valid email');
    }
  };

  const onFocusHandle = () => {
    if (error) {
      setError(undefined);
    }
  };

  const onChangeHandle = (e: SyntheticEvent, dataType: string) => {
    const target = e.target as HTMLInputElement;
    setValue({ ...value, [dataType]: target.value });
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form">
        <button className="close-button" onClick={onClose}>
          <CloseIcon />
        </button>

        <img className="logo" src={LogoLabel} alt="sfy-logo" />

        <div className="contact-short-message">
          <span className="sub-title">
            {type === 'user' ? subTitle?.user : subTitle?.provider}
          </span>
        </div>

        <form onSubmit={onRequestApp} className={`request-form`}>
          {error && <span className="error">{error}</span>}
          <label>Email *</label>
          <input
            data-testid="request-form-input"
            placeholder="Email"
            value={value?.email}
            onChange={e => onChangeHandle(e, 'email')}
            onFocus={onFocusHandle}
            required
            className="contact-input"
          />
          {type === 'provider' && (
            <>
              <label>Business Name *</label>
              <input
                data-testid="request-form-input"
                placeholder="Business Name"
                value={value?.name}
                onChange={e => onChangeHandle(e, 'name')}
                onFocus={onFocusHandle}
                required
              />
              <label>City *</label>
              <input
                data-testid="request-form-input"
                placeholder="City"
                value={value?.city}
                onChange={e => onChangeHandle(e, 'city')}
                onFocus={onFocusHandle}
                required
              />
            </>
          )}
          <button
            disabled={loading}
            data-testid="request-form-btn"
            className="contact-btn"
            type="submit">
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
