import Footer from '../../components/Footer';
import Header from '../../components/Header';
import WelcomeImage from '../../components/WelcomeImage';
import BackgroundImage from '../../assets/images/playlocal/playlocal-welcome.jpg';
import '../../styles/_landings.scss';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlide';

export const PlayLocal = () => {
  return (
    <>
      <div className="landing">
        <div className="landing-home">
          <Header />
          <WelcomeImage
            image={BackgroundImage}
            title={
              <h1>
                Play <span className="green">Local</span>
              </h1>
            }
            subtitles={
              <>
                <h2>
                  Find compare and book{' '}
                  <span className="green">local sports</span>
                </h2>
                <h2>
                  <span className="green">Play and learn </span> in your
                  community
                </h2>
                <h2>
                  Promote and sell{' '}
                  <span className="green">to more customers</span>
                </h2>
              </>
            }
          />
        </div>
        <SecondSlide />
        <ThirdSlide />
        <Footer />
      </div>
    </>
  );
};

export default PlayLocal;
