import './_welcomeImage.scss';
type Props = {
  title: JSX.Element;
  subtitles: JSX.Element;
  image: string;
  extraClass?: string;
};

export const WelcomeImage = ({
  title,
  subtitles,
  image,
  extraClass,
}: Props) => {
  return (
    <div className="welcome-image">
      <div
        className={`welcome-image-content ${extraClass}`}
        style={{
          backgroundImage: `url(${image})`,
        }}>
        <div className="welcome-image-overlay" />
      </div>
      <div className="welcome-image-title">
        {title}
        <span className="label">
          by <span className="green">sporti</span>
        </span>
      </div>
      <div className="welcome-image-subtitle">{subtitles}</div>
    </div>
  );
};

export default WelcomeImage;
