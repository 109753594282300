import React, { useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '../../../hooks/IsInViewport';
import { useViewportSize } from '../../../hooks/useViewportSize';
import './_imageSection.scss';

export type ImageSectionProps = {
  id: string;
  image: string;
  fullWidth?: boolean;
  fullWidthImg?: string;
  title: React.ReactNode;
};
interface ImageSectionExtendedProps extends ImageSectionProps {
  bgOpacity: number;
  bg: string;
  bw: boolean;
}


export const ImageSection = ({
  id,
  image,
  title,
  fullWidthImg,
  fullWidth = false,
  bg,
  bgOpacity,
  bw
}: ImageSectionExtendedProps) => {
  const [animation, setAnimation] = useState(false);
  const sectionRef = useRef(null);
  const { width } = useViewportSize();
  const isInViewport = useIsInViewport(sectionRef);

  useEffect(() => {
    if (!animation && isInViewport) {
      setAnimation(true);
    }
  }, [isInViewport]);

  return (
    <div
      ref={sectionRef}
      className={`image-section ${fullWidth && 'fullW'} ${
        animation && 'fadeIn'
      }`}>
      <img src={width <= 900 && fullWidthImg ? fullWidthImg : image} alt={id} style={{ filter: bw ? 'grayscale(100%)':'none' }}/>
      <div className="overlay"  style={{background: bg, opacity: bgOpacity }}/>
      {title}
    </div>
  );
};

export default React.memo(ImageSection);
