import React from 'react';
import './_secondSlide.scss';
import '../../../styles/_landings.scss';

import image1 from '../../../assets/images/playlocal/playlocal-group1.jpg';
import image2 from '../../../assets/images/playlocal/playlocal-group2.jpg';
import image3 from '../../../assets/images/playlocal/playlocal-group3.jpg';
import image4 from '../../../assets/images/playlocal/playlocal-group4.jpg';

const SecondSlide = () => {
  return (
    <div className="playlocal-second-slide">
      <div className="playlocal-second-slide-images">
        <div className="playlocal-second-slide-images-first">
          <img src={image1} alt="passsport" />
          <img src={image2} alt="passsport" />
        </div>
        <div className="playlocal-second-slide-images-second">
          <img src={image3} alt="passsport" />
          <img src={image4} alt="passsport" />
        </div>
        <div className="playlocal-second-slide-images-overlay" />
      </div>
      <div className="playlocal-second-slide-subtitles">
        <div className="playlocal-second-slide-subtitles-container">
          <h2>
            <span className="green">Find</span> teams clubs and leagues
          </h2>
          <h2>
            <span className="green">Compare</span> camps classes and schools
          </h2>
          <h2>
            <span className="green">Book</span> tournaments and competitions
          </h2>
        </div>
      </div>
    </div>
  );
};

export default SecondSlide;
