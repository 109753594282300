import Header from '../../components/Header';
import WelcomeImage from '../../components/WelcomeImage';
import BackgroundImage from '../../assets/images/skillsharer/skillsharer-welcome.jpg';
import '../../styles/_landings.scss';
import Footer from '../../components/Footer';
import SecondSlide from './SecondSlide';

export const SkillSharer = () => {
  return (
    <>
      <div className="landing">
        <div className="landing-home">
          <Header />
          <WelcomeImage
            image={BackgroundImage}
            extraClass="bg-top"
            title={
              <h1>
                <span className="green">Skill</span> Sharers
              </h1>
            }
            subtitles={
              <>
                <h2>Improve your technique and game</h2>
                <h2>Learn and try something new</h2>
                <h2>
                  Make money <span className="green">sharing</span> your skills
                </h2>
              </>
            }
          />
        </div>
        <SecondSlide />
        <Footer />
      </div>
    </>
  );
};

export default SkillSharer;
