import Footer from '../../components/Footer';
import Header from '../../components/Header';
import WelcomeImage from '../../components/WelcomeImage';
import BackgroundImage from '../../assets/images/passsport/passsport-welcome.jpg';
import '../../styles/_landings.scss';
import SecondSlide from './SecondSlide';

export const PassSport = () => {
  return (
    <>
      <div className="landing">
        <div className="landing-home">
          <Header />
          <WelcomeImage
            image={BackgroundImage}
            title={
              <h1>
                Pass-<span className="green">Sport</span>
              </h1>
            }
            subtitles={
              <>
                <h2>
                  Book local <span className="green">sport</span> activities
                </h2>
                <h2>
                  At vacation <span className="green">destinations</span>
                </h2>
                <h2>
                  On your business <span className="green">travel</span>
                </h2>
              </>
            }
          />
        </div>
        <SecondSlide />

        <Footer />
      </div>
    </>
  );
};

export default PassSport;
