import React from 'react';
import './_secondSlide.scss';
import '../../../styles/_landings.scss';

import logo from '../../../assets/icons/logo.png';
import image1 from '../../../assets/images/sportyclub/sporty-1.png';
import image2 from '../../../assets/images/sportyclub/sporty-2.jpg';
import image3 from '../../../assets/images/sportyclub/sporty-3.jpg';
import image4 from '../../../assets/images/sportyclub/sporty-4.png';
import image5 from '../../../assets/images/sportyclub/sporty-5.jpg';
import image6 from '../../../assets/images/sportyclub/sporty-6.jpg';
import Apple from '../../../assets/images/apple.svg';
import Android from '../../../assets/images/android.svg';

const SecondSlide = () => {
  return (
    <div className="sporty-slide">
      <div className="sporty-slide-container">
        <div className="sporty-slide-images">
          <img className="sporty-logo" src={logo} alt="logo" />
          <div className="sporty-slide-images-container">
            <img src={image1} alt="sporty-club" />
            <img src={image2} alt="sporty-club" />
            <img src={image3} alt="sporty-club" />
            <img src={image4} alt="sporty-club" />
            <img src={image5} alt="sporty-club" />
            <img src={image6} alt="sporty-club" />
          </div>
          <div className="sporty-slide-images-overlay" />
        </div>
        <div className="welcome-image flex flex-col justify-between">
          <div className="welcome-image-box flex-grow">
            <div className="welcome-image-title">
              <h1>THE SPORTY CLUB</h1>
              <span className="label">
                by <span className="green">sporti</span>
              </span>
            </div>
            <div className="welcome-image-subtitle">
              <h2>Are you sporty? </h2>
              <h2>
                Free rewards <span className="green"> points</span> to spend
              </h2>
              <h2>Meet new friends and teammates</h2>
            </div>
          </div>
          <div className="sporty-store-button flex-grow">
            <a href={window.location.hostname} title="Check Sporforya IOS app">
              <img src={Apple} alt="Apple" className="app-image" />
            </a>
            <a
              href={window.location.hostname}
              title="Check Sporforya Android app">
              <img src={Android} alt="Apple" className="app-image" />
            </a>
          </div>
        </div>
      </div>
      <div className="sporty-colors">
        <div className="sporty-colors-green"></div>
        <div className="sporty-colors-transparent"></div>
        <div className="sporty-colors-green"></div>
        <div className="sporty-colors-transparent"></div>
      </div>
    </div>
  );
};

export default SecondSlide;
