import { useEffect } from 'react';
import './_toast.scss';

import { ReactComponent as CheckIcon } from '../../assets/icons/check-fill.svg';
type Props = {
  testId?: string;
  open: boolean;
  title: string;
  message: string;
  closeTime?: number;
  onClose: () => void;
};

export const Toast = ({
  testId,
  open,
  title,
  message,
  closeTime = 4000,
  onClose,
}: Props) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, closeTime);
    }
  }, [open]);

  return (
    <div
      data-testid={testId || 'toast'}
      className={`toast ${open && 'active'}`}>
      <div className="toast-icon">
        <CheckIcon />
      </div>
      <div className="toast-message">
        <span className="toast-title">{title}</span>
        <p className="toast-description">{message}</p>
      </div>
    </div>
  );
};

export default Toast;
