import { useEffect, useRef, useState } from 'react';
import './_landingPage.scss';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-up.svg';

// Company Logo
import Logo from '../../assets/icons/logo.png';

// Background Images
import SoftballImage from '../../assets/images/latest-images/softball_adult_team.jpg';
import BasketballCampImage from '../../assets/images/latest-images/basketball_camp.jpg';
import DanceBalletImage from '../../assets/images/latest-images/dance_ballet.jpg';
import SwimLessonsImage from '../../assets/images/latest-images/swim_lessons.jpg';
import MarathonImage from '../../assets/images/latest-images/marathon.jpg';
import BeachImage from '../../assets/images/latest-images/beach.jpg';
import SoccerImage from '../../assets/images/latest-images/soccer.jpg';
import SurfImage from '../../assets/images/latest-images/surf.jpg';
import MartialArtsImage from '../../assets/images/latest-images/martial-arts.jpg';
import SnowboardingImage from '../../assets/images/latest-images/snowboarding.jpg';

// Background Images FullWidth
import BasketballCampFullWImage from '../../assets/images/fullw/basketball_camp.jpg';
import DanceBalletFullWImage from '../../assets/images/fullw/dance_ballet.jpg';
import BeachuFullWImage from '../../assets/images/fullw/beach.jpg';
import SoccerFullWImage from '../../assets/images/fullw/soccer.jpg';
import MartialArtsFullWImage from '../../assets/images/fullw/martial-arts.jpg';
import SurfFullWImage from '../../assets/images/fullw/surf.jpg';

import ImageSection, { ImageSectionProps } from './ImageSection';
import Footer from '../../components/Footer';
import HeroSection from './HeroSection';

const landingImageSections: ImageSectionProps[] = [
  {
    id: 'softball',
    title: (
      <h3>
        Find Community
        <span className="title-green">Teams</span>
      </h3>
    ),
    image: SoftballImage,
    fullWidth: true,
  },
  {
    id: 'basketball',
    title: (
      <h1 className="">
        <span>Compare</span>
        <span className="title-green">Camps</span>
        <span>and Courses</span>
      </h1>
    ),
    image: BasketballCampImage,
    fullWidthImg: BasketballCampFullWImage,
  },
  {
    id: 'beach',
    title: (
      <h1 className="">
        <span>Experience</span>
        <span>activities</span>
        <span>
          on
          <span className="title-green"> Vacation</span>
        </span>
      </h1>
    ),
    image: BeachImage,
    fullWidthImg: BeachuFullWImage,
  },
  {
    id: 'swimlessons',
    title: (
      <h3>
        Learn in Group
        <span className="title-green"> Lessons</span>
      </h3>
    ),
    image: SwimLessonsImage,
    fullWidth: true,
  },
  {
    id: 'danceballet',
    title: (
      <h1 className="">
        <span>Reserve</span>
        <span>Places in </span>
        <span className="title-green">Tryouts</span>
      </h1>
    ),
    image: DanceBalletImage,
    fullWidthImg: DanceBalletFullWImage,
  },
  {
    id: 'martialarts',
    title: (
      <h1 className="">
        <span>Try</span>
        <span>Something</span>
        <span className="title-green">Different</span>
      </h1>
    ),
    image: MartialArtsImage,
    fullWidthImg: MartialArtsFullWImage,
  },
  {
    id: 'marathon',
    title: (
      <h3>
        Take Part in more
        <span className="title-green"> Events</span>
      </h3>
    ),
    image: MarathonImage,
    fullWidth: true,
  },

  {
    id: 'soccer',
    title: (
      <h1 className="">
        <span>Register </span>
        <span>in your </span>
        <span className="title-green">Competitions</span>
      </h1>
    ),
    image: SoccerImage,
    fullWidthImg: SoccerFullWImage,
  },
  {
    id: 'surf',
    title: (
      <h1 className="">
        <span>Sign up</span>
        <span>with Friends</span>
        <span>
          for
          <span className="title-green"> Classes</span>
        </span>
      </h1>
    ),
    image: SurfImage,
    fullWidthImg: SurfFullWImage,
  },
  {
    id: 'snowboarding',
    title: (
      <h3>
        Share feedback with 
        <span className="title-green"> Reviews</span>
      </h3>
    ),
    image: SnowboardingImage,
    fullWidth: true,
  },
];
const bgColors = ['#000','#0d426a'];

const LandingPage = () => {
  const [floatBtn, setFloatBtn] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef(window.scrollY);
  const [bg] = useState(true);
  const [opacity] = useState(0.64)
  const [bw] = useState(false);

  const onScrollHandle = (e: Event) => {
    const target = e.target as HTMLDivElement;

    if (
      target.scrollTop < scrollPosition.current &&
      target.scrollTop > window.innerHeight &&
      !floatBtn
    ) {
      setFloatBtn(true);
    }
    if (
      (target.scrollTop > scrollPosition.current ||
        target.scrollTop < window.innerHeight) &&
      floatBtn
    ) {
      setFloatBtn(false);
    }
    scrollPosition.current = target.scrollTop;
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    contentElement?.addEventListener('scroll', onScrollHandle);

    return () => {
      contentElement?.removeEventListener('scroll', onScrollHandle);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floatBtn]);

  const onScrollTopHandle = () => {
    if (!headerRef.current) {
      return;
    }
    headerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page" ref={contentRef}>
      <div ref={headerRef} className="landing-page-header">
        <img src={Logo} alt="logo" />
      </div>

      {floatBtn && (
        <button className="float-btn" onClick={onScrollTopHandle}>
          <ArrowIcon />
        </button>
      )}

      <div className="landing-page-images-section">
        <HeroSection bgOpacity={opacity} bg={bg?bgColors[0]:bgColors[1]} bw={bw}/>
      </div>

      <div className="landing-page-images-section">
        {landingImageSections.map(section => (
          <ImageSection key={section.id} {...section} bgOpacity={opacity} bg={bg?bgColors[0]:bgColors[1]} bw={bw} />
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
