import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import LandingPage from './pages/LandingPage';
import PassSport from './pages/PassSport';
import PlayLocal from './pages/PlayLocal';
import SkillSharer from './pages/SkillSharer';
import SportyClub from './pages/SportyClub';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';


export const App = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get('utm_source');
    ReactGA.initialize('G-K709026MPY');
    ReactGA.event({category: "route_sporti", action: `lp_view${source ? "_" + source : ""}`, label: `${window.location.pathname}${window.location.search}`});
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/play-local" element={<PlayLocal />} />
        <Route path="/sporty-club" element={<SportyClub />} />
        <Route path="/skill-sharer" element={<SkillSharer />} />
        <Route path="/pass-sport" element={<PassSport />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
